import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import './css/Sing.css';
import $ from 'jquery';
// Import image We Can't Wait_Holder.png
import ReactPlayer from 'react-player';
import { useCallback } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


import {useState , useEffect} from 'react';
// Import ../images/FullLogoStrap.png as Bannerimage
import BannerImage from '../images/FullLogoStrap.png';
// import ReactPlayer from 'react-player';
import MobileBannerImage from '../images/cleanLogo.png';
import RaysOnly from '../images/RaysOnly.png';
import KellyImage from '../images/Kelly.png';
import FooterImage from '../images/FooterImage.png';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import FooterText from './FooterText';
import PraiseNav from './PraiseNav';
function Sing() {
  const urlParams = new URLSearchParams(window.location.search);
  const emailParam = urlParams.get('email');
  let schoolEmail;
  const MySwal = withReactContent(Swal);
  const [emailSent, setEmailSent] = useState(false);
  const [emailConfirmed, setEmailConfirmed] = useState(false);
  // Add this near your other useState declarations
const [selectedSong, setSelectedSong] = useState(null);
  
  const sendEmail = useCallback(async (email) => {
    if (emailSent) {
      return; // Email already sent, skip further execution
    }
    const emailToUse = email || schoolEmail; // If email is provided, use it; otherwise, use schoolEmail
    if (emailToUse) {
      // You can customize the SweetAlert modal here
      const elements = document.getElementsByClassName("to-remove");
                            while (elements.length > 0) {
                                elements[0].parentNode.removeChild(elements[0]);
                            }
                            Swal.fire({
                              title: 'Loading',
                              text: 'Please wait for your email address to be verified...',
                              allowOutsideClick: false, // Prevent clicking outside to close
                              showConfirmButton: false // Remove the "Okay" button
                          });
                          
    }
    const response = await axios.get("/api/verifyEmail", {
        params: { schoolEmail: emailToUse, month: "april2025" },
    })
        .then((response) => {
            console.log(response);
            if (response.data === "False") {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    html: 'Your school has not been registered, please register or ' +
                        '<a href="/contact">contact us</a>. '
                }).then (() => {
                  // Go to /sing
                  window.location.href = "/sing";
                });
            } else {
                MySwal.close();
                // Find and replace the existing Swal.fire with checkboxes section:

                Swal.fire({
                  title: "Terms of use:",
                  html: `
                      <style>
                          .checkbox-container { margin-bottom: 20px; text-align: left; }
                          .radio-input {
                              appearance: none;
                              -webkit-appearance: none;
                              width: 20px;
                              height: 20px;
                              border: 2px solid #ccc;
                              border-radius: 50%;
                              outline: none;
                              cursor: pointer;
                              position: relative;
                              margin-right: 10px;
                              vertical-align: middle;
                          }
                          .radio-input:checked {
                              background-color: #4CAF50;
                              border-color: #4CAF50;
                          }
                          .radio-input:checked::after {
                              content: '';
                              position: absolute;
                              left: 6px;
                              top: 2px;
                              width: 5px;
                              height: 10px;
                              border: solid white;
                              border-width: 0 2px 2px 0;
                              transform: rotate(45deg);
                          }
                          .checkbox-label { display: block; margin-bottom: 5px; }
                      </style>
                      <div class='checkbox-container'>

                          <div>
                            <span style="text-align:center;">Our lyric videos are licensed for collective worship use only.</span><br/><br/>

                              <b>Schools with a CCLI licence:</b>
                              <div style="margin-left: 15px; margin-bottom: 15px; margin-top: 10px;">
                                  Log all displays and projections on your CCLI report.<br/>
                                  This helps to fund our work.
                                  <br/>
                                  <input type="radio" name="licenceType" id="withLicence" class="radio-input" style="margin-bottom:5px;">
                                  <label for="withLicence">Agree</label>
                              </div>
                          </div>
                          <div>
                              <b>Schools without a CCLI licence:</b>
                              <div style="margin-left: 15px; margin-top: 10px; ">
                                 Only view directly from our website on a personal computer (PC) screen. 
                                  <br/>
                                  <input type="radio" name="licenceType" id="withoutLicence" class="radio-input" style="margin-bottom:5px;">
                                  <label for="withoutLicence">Agree</label>
                              </div>
                          </div>
                      </div>
                  `,
                  confirmButtonText: 'Continue',
                  showCancelButton: true,
                  allowOutsideClick: false,
                  preConfirm: () => {
                      const withLicence = document.getElementById('withLicence').checked;
                      const withoutLicence = document.getElementById('withoutLicence').checked;
                      if (!withLicence && !withoutLicence) {
                          Swal.showValidationMessage('Please select one of the options');
                          return false;
                      }
                      return { withLicence, withoutLicence };
                  }
              }).then((result) => {
                  if (result.isConfirmed) {
                      Swal.fire({ icon: 'success', text: 'Thank you for confirming!' });
                      setEmailConfirmed(true);
                      setVideoUrl(response.data[0]);
                      // Remove all elements where class is to-remove
                      const elements = document.getElementsByClassName("to-remove");
                      while (elements.length > 0) {
                          elements[0].parentNode.removeChild(elements[0]);
                      }
                  } else {
                      window.location.reload();
                  }
              });
                setEmailSent(true);
            }
        })
        .catch((err) => {
            console.log(err);
        });

    console.log(response);
}, [MySwal, schoolEmail, emailSent]);
// const printLyrics = () => {
//   const pdfUrl = "https://praisealongpublic.blob.core.windows.net/praisealongpublic/Moving Along.pdf";

//   // Open the PDF in a new window
//   window.open(pdfUrl, "_blank");
// };


// const printLyricsThree = () => {
//   const pdfUrl = "https://praisealongpublic.blob.core.windows.net/praisealongpublic/In the Spring.pdf";

//   // Open the PDF in a new window
//   window.open(pdfUrl, "_blank");
// };
  const register = (e) => {

    let inputtedSchool = $("#formBasicSchoolName").val();
    let inputtedEmail = $("#formBasicSchoolEmail").val();
    let inputtedDiocese = $("#formBasicDiocese").val();
    var checkedValue = $('#registerCheckbox').is(':checked');
    var checkedCCLI = true;
    
    console.log(checkedValue);
   console.log(inputtedDiocese);
  //  Check if school email ends with .sch.uk
  if(inputtedEmail.endsWith('.sch.uk')){
    
    //  Check if hool name is not empty
    if(inputtedSchool !== ""){
      // Check if diocese is not empty
      if(inputtedDiocese !== null){
        // Check if Privacy Policy is checked
        if(checkedValue === true){
          if(checkedCCLI === true){
            let stringtoFetch = '/api/message?diocese="nodiocese"&email=' + inputtedEmail+'&school=' + inputtedSchool+'&requestType=register';
            fetch(stringtoFetch)
      .then(response => response.json())
      .then(data => 
        console.log(data)
        );
      Swal.fire(
        'Registration Received!',
        'Thank you for your message, we\'ll be in touch. Please make sure you check your junk email.',
        'success'
      )
          }
          else {
            Swal.fire({
              icon: 'info',
              title: 'Oops...',
              html: 'You need a Collective worship licence from CCLI to be able to use our free resources. <a href="https://uk.ccli.com/copyright-licences/#school-licences" target="_blank">Get help here</a>.',
            });
          }
          
          // Check if CCLI is checked
          
          
      

          
          
        }
        else{
          Swal.fire({
            icon: 'info',
            title: 'Oops...',
            text: 'Please agree to our Privacy Policy',
          })
        }
      }
      else{
        Swal.fire({
          icon: 'info',
          title: 'Oops...',
          text: 'Please enter your diocese',
        })
      }
    }
    else{
      Swal.fire({
        icon: 'info',
        title: 'Oops...',
        text: 'Please enter your school name',
      })
    }
  }
  else{
    Swal.fire({
      icon: 'info',
      html: `Struggling to register your school? Don't worry!<br><a href="/contact">Contact us</a><br>We'll soon have you up and running!`,
    })
  }
   
   
    e.preventDefault();
  }
  const [videoUrl, setVideoUrl] = useState('');
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  
  
  useEffect(() => {
    // Update window width on resize
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
  
    window.addEventListener('resize', handleResize);
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [sendEmail, emailParam]);  // Include sendEmail in the dependency array

  
  
  // Get the email url param
  
  
  useEffect(() => {
    if (emailParam) {
      // If email param is provided, send it to the API
      sendEmail(emailParam);
    }
  }
  , [emailParam, sendEmail]);
  const bannerImage = windowWidth < 850 ? MobileBannerImage : BannerImage;
  return (
    <>
    

      <div className="gradient-background"></div>
      <div className="navbar-container">
      <PraiseNav />

      </div>
      <a href="/" className="text-decoration-none">

      <img src={bannerImage} alt="New sing-along" className="banner-image mt-5 mb-5" />
      </a>

        <div className="row">
            <div className="col"></div>
            <div className={selectedSong ? "col-10 col-sm-10 col-md-5 col-lg-4 mx-auto px-0" : "col-12 col-sm-12 col-md-8 col-lg-6 mx-auto px-0"}>
            

            {videoUrl !== "" ? (
  !selectedSong ? (
    <div className="text-center">
      <h2 className="landing-header-large mb-5" style={{fontSize: "50px"}}><b>APRIL</b></h2>
      <div className="song-selection-grid">
        
        <Container style={{marginBottom:"200px"}}>
          <Row>
            <Col>
            <div 
          className="song-card" 
          onClick={() => setSelectedSong('risen')}
        >
          {/* Image  https://praisealongpublic.blob.core.windows.net/praisealongpublic/Seeds of Sunshine_SM.png */}
          <img src="https://praisealongpublic.blob.core.windows.net/praisealongpublic/For He is Risen_SM.png" alt="Seeds of Sunshine" className="song-image w-100 mb-4" />
          <h3 className="futura-fix">Sing "For He is Risen"</h3>
        </div>
            </Col>
          <Col>
          <div 
          className="song-card" 
          onClick={() => setSelectedSong('sunshine')}
        >
                    <img src="https://praisealongpublic.blob.core.windows.net/praisealongpublic/Seeds of Sunshine_SM.png" alt="Seeds of Sunshine" className="mb-4 song-image w-100" />

          <h3 className="futura-fix">Sing "Seeds of Sunshine"</h3>
        </div>
          </Col>
          </Row>
          
        </Container>
        
        {/* Add more song cards here as needed */}
      </div>
    </div>
  ) : (
<div className="text-center">
  {selectedSong === "risen" && (
    <>
      <div className="download-button mt-5 mb-5">
        <Button 
          variant="primary border-0" 
          onClick={() => setSelectedSong(null)}
        >
          <span className="button-text">Back</span>
        </Button>
      </div>
      <ReactPlayer 
        url="https://praisealongpublic.blob.core.windows.net/praisealongpublic/For He is Risen_2025.mp4" 
        controls={true} 
        width="100%" 
        height="auto" 
        className="mb-5" 
      />
    </>
  )}
  {selectedSong === "sunshine" && (
    <>
      <div className="download-button mt-5 mb-5">
        <Button 
          variant="primary border-0" 
          onClick={() => setSelectedSong(null)}
        >
          <span className="button-text">Back</span>
        </Button>
      </div>
      <ReactPlayer 
        url="https://praisealongpublic.blob.core.windows.net/praisealongpublic/Seeds of Sunshine_2025.mp4" 
        controls={true} 
        width="100%" 
        height="auto" 
        className="mb-5" 
      />
    </>
  )}

  <div className="row homepage-content">
    <div className="col"></div>
    <div className="">
      <span className="landing-header-large"><b>LEADERS' NOTES</b></span><br/>
      <span className="" style={{color:"rgb(212, 137, 185)"}}>For teachers and children who lead worship.</span>
      <br /><br />
      {selectedSong === "risen" && (
        <span className="about-content" style={{lineHeight:"20px"}}>
                   <span className="" style={{color:"rgb(180, 68, 133)"}}>Theme:</span><br/><span style={{color:"rgb(212, 137, 185)"}}>Joy</span><br/><br/>
                   <span className="" style={{color:"rgb(180, 68, 133)"}}>Focus:</span><br/><span style={{color:"rgb(212, 137, 185)"}}>The Resurrection of Jesus</span><br/><br/>
                   This April we are exploring the Easter story, where sadness is replaced with great joy as Jesus rises from death, bringing people the hope of everlasting life! <br/><br/>
                   It is during this season that we also see nature coming to life again as the death and decay of winter give way to the joys of spring. <br/><br/>
                   Easter reminds us that sad times will pass and joy will come again! <br/><br/>
                   We can read about the Resurrection of Jesus here: <br></br><br/>
                   <span className="" style={{color:"rgb(180, 68, 133)"}}>Reading:</span><br/><span style={{color:"rgb(212, 137, 185)"}}>Matthew Chapter 28, Verses 1-10</span><br/><br/>
                    <a href="https://www.biblegateway.com/passage/?search=Matthew%2028:1-10&version=NIV">Bible Reading</a><br/><br/>
                    <span className="" style={{color:"rgb(180, 68, 133)"}}>Reflection:</span><br/><span style={{color:"rgb(212, 137, 185)"}}>By Methodist Minister Revd Adrain Perry</span><br/><br/>
                   <i>Dear God,  <br/>

Thank you for the amazing news that 'He is risen’ <br/>

Thank you that you have given us all kinds of ways to celebrate that!<br/> 

We are so grateful that we can sing with our lips  <br/>

But we want to also sing with our hearts <br/>

As you surprised people with that wonderful news on the first Easter day <br/>

Please surprise us again and give us new joy in this wonderful season. <br/>

In Jesus’ name. </i> 
<br/><br/>
Amen

        </span>
      )}
      {selectedSong === "sunshine" && (
        <span className="about-content" style={{lineHeight:"20px"}}>
          <span className="" style={{color:"rgb(180, 68, 133)"}}>Theme:</span><br/><span style={{color:"rgb(212, 137, 185)"}}>Positivity</span><br/><br/>
          <span className="" style={{color:"rgb(180, 68, 133)"}}>Focus:</span><br/><span style={{color:"rgb(212, 137, 185)"}}>The Month of the Military Child</span><br/><br/>
          Did you know that April is the Month of the Military Child? It’s a time when we are invited to show support for children whose parents and carers serve or have served in the in the armed forces. <br/><br/>
          Children from military families often move from place to place because of the work their parents are called to do. They are sometimes compared to dandelions because they must adapt to new surroundings wherever they go. <br/><br/>
          “Seeds of Sunshine” is a special song, inspired by the Service Children’s Community Choir. It shares some of the emotions the children have experienced when moving from place to place. They hope it will inspire us all to stay positive when life’s changes come along. <br/><br/>
          <span className="" style={{color:"rgb(180, 68, 133)"}}>Reading:</span><br/><span style={{color:"rgb(212, 137, 185)"}}>Matthew Chapter 4, Verse 18 -22</span><br/><br/>
          The Bible is full of stories about people who adapted to new surroundings in order do God’s work. For example, Jesus called His first followers to leave the lives they were used to in order to help Him reach more people with God’s love. They didn’t know where this work would take them but they were positive about it because they knew that it was important and that Jesus would help them along the way. <br/><br/>
          <a href="https://www.biblegateway.com/passage/?search=Matthew%204:18-22&version=ICB">Bible Reading</a><br/><br/>
          <span className="" style={{color:"rgb(180, 68, 133)"}}>Reflection:</span><br/><span style={{color:"rgb(212, 137, 185)"}}>Let’s take a moment of silence to listen and think...</span><br/><br/>
          Why do we think the first disciples were so positive about following Jesus, 

even though they had to leave the lives they knew behind? <br/><br/>
          <span className="" style={{color:"rgb(180, 68, 133)"}}>Response:</span><br/><span style={{color:"rgb(212, 137, 185)"}}>Invite a few (3) suggestions </span><br/><br/>
          How can we stay positive in times of change? <br/><br/>
          How can we help to make sure our school is a happy and welcoming place for everyone? <br/><br/>
          <span className="" style={{color:"rgb(180, 68, 133)"}}>Prayer:</span><br/><span style={{color:"rgb(212, 137, 185)"}}>Shared by Padre Andy Grant, Chaplain to the Forces:</span><br/><br/>
          <i>Dear Heavenly Father <br/>

Thank you for the gift of music and song <br/>

Fill our hearts with love and kindness as we meet to sing. <br/>

May our singing spread joy and happiness to all <br/>

and bring glory to your name. </i>
<br/><br/>
Amen
        </span>
      )}
    </div>
  </div>

  {emailConfirmed && (
    <div className="row homepage-content trans-white-bg-second pt-5 pb-5 mt-5">
      <div className="col"></div>
      <div className="col-10 ">
        <b style={{ color: 'rgb(212, 24, 118)', fontSize: '24px' }} className="keep-and-collect"><span style={{color:"rgb(212, 137, 185)"}}>SONG PACK</span> £6</b><br/><br/>
       

        {selectedSong === "risen" && (
          <>
            
            
            <span className="" style={{color:"rgb(180, 68, 133)"}}>Pack includes:</span>
            <br/><br />
            Typeset lyric sheet <br/><br/>
            Piano and lead vocal score <br/><br/>
            Downloadable backing track (mp3) <br/><br/>
            Downloadable guide track (mp3) <br/><br/>
            Licensing information <br/>
            <div className="row mt-5 mb-2">
    <div className="col"></div>
    <div className="col-10 col-sm-10 col-md-10 col-lg-10 px-0 mt-4 mb-5">
      <div className="download-button">
        <img src={RaysOnly} alt="Background" className="background-image" />
        <Button variant="primary border-0" className="" onClick={() => window.location.href = `mailto:info@praise-along.com?subject=Song Pack Order - ${selectedSong === 'risen' ? 'For He is Risen' : 'Seeds of Sunshine'}`}><span className="button-text">ORDER</span></Button>
        </div>
    </div>
    
    <div className="col"></div>
  </div>
  <span style={{fontSize:"15px"}}>We will contact you to process your order.</span>
  

          </>
        )}
        {selectedSong === "sunshine" && (
        <>
         <span className="" style={{color:"rgb(180, 68, 133)"}}>The more we praise the more we raise!</span><br/><br/>
        Fifty percent of all proceeds made this April from the song “Seeds of Sunshine” will be donated to the Service Children’s Community Choir and used to support more children from military families.<br/><br/>
        
        <span className="" style={{color:"rgb(180, 68, 133)"}}>Pack includes:</span>
        <br/><br />
        Typeset lyric sheet <br/><br/>
        Piano and lead vocal score <br/><br/>
        Downloadable backing track (mp3) <br/><br/>
        Downloadable guide track (mp3) <br/><br/>
        Licensing and fundraising information <br/>
        <div className="row mt-5 mb-2">
<div className="col"></div>
<div className="col-10 col-sm-10 col-md-10 col-lg-10 px-0 mt-4 mb-5">
  <div className="download-button">
    <img src={RaysOnly} alt="Background" className="background-image" />
    <Button variant="primary border-0" className="" onClick={() => window.location.href = `mailto:info@praise-along.com?subject=Song Pack Order - ${selectedSong === 'risen' ? 'For He is Risen' : 'Seeds of Sunshine'}`}><span className="button-text">ORDER</span></Button>
    </div>
</div>

<div className="col"></div>
</div>
<span style={{fontSize:"15px"}}>We will contact you to process your order.</span>


      </>
        )}
      </div>
      <div className="col"></div>
    </div>
  )}
</div>
  
  
     )
) : null}




            </div>
            <div className="col"></div>
            {selectedSong && emailConfirmed && (
 <div className="row homepage-content pt-5 pb-5">
 <div className="col"></div>
 <div className="col-10 col-sm-10 col-md-5 col-lg-4 mx-auto px-0" >
  
 <b>About the Composer</b><br/>
<div style={{fontSize:"15px", marginTop: "10px"}}>
<p>Hi I’m Kelly. I'm a songwriter! I have contributed to lots of popular and 
award-winning assembly <a href="https://www.outoftheark.co.uk/products/writer/kelly-fort/">songbooks</a> and have also worked in schools and 
churches for many years, helping to lead collective worship and singing 
assemblies.</p>
</div>
<img src={KellyImage} alt="REAccredited" class="img-fluid" />
<div style={{fontSize:"15px"}} className="mt-4">
<p>I love writing for collective worship because it helps people to identify 
shared values and invites us to respond in our own hearts to God’s 
unconditional love for us all.
<br/><br/>
Recently, I have started releasing my music independently. I hope you like it 
and welcome any feedback you can offer. Feel free to get in touch with me 
at <a href="mailto:info@praise-along.com">info@praise-along.com</a></p>
</div>
  
 </div>
 <div className="col " ></div>
</div>  
            )}
   
        </div>


       
<div className="row to-remove">
            <div className="col"></div>
            <div className="col-10 col-sm-10 col-md-5 col-lg-4 mx-auto px-0 mt-5 mb-5"  id="registerScrollTo">
              <div className="register-header">
                REGISTER
              </div>
              <div className="register-content">
              Join our network of registered schools to access free monthly song videos and/or purchase sheet music and backing tracks for collective worship singing.
              </div>

              {/* Form for email, diocese, and two checkboxes for Privacy Policy and CCLI Lincense */}
              <Form className="futura-font">
                <Form.Group className="mb-3" controlId="formBasicSchoolEmail">
                  <Form.Control type="email" placeholder="Email address" />
                  </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicSchoolName">
                  <Form.Control type="email" placeholder="Enter school name" />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicRegisterCheckbox">
  <div className="checkbox-wrapper">
    <Form.Check
      type="checkbox"
      id="registerCheckbox"
    />
    <Form.Label htmlFor="registerCheckbox" id="registerCheckbox" className="checkbox-label">
      <span className="blue-futura">
        Agree to our <a href="/privacy">privacy policy</a>.
      </span>
    </Form.Label>
  </div>
</Form.Group>

                </Form>
                <div className="container pt-0 mt-5">
  <div className="row homepage-content">
    <div className="col"></div>
    <div className="col-10 col-sm-10 col-md-10 col-lg-10 px-0 mt-5 mb-5">
      <div className="download-button">
        <img src={RaysOnly} alt="Background" className="background-image" />
        <Button variant="primary border-0" className="" onClick={register}><span className="button-text">Register!</span></Button>
        </div>
    </div>
    <div className="col"></div>
  </div>
</div>
            </div>
            <div className="col"></div>
        </div>
        

<FooterText />
     
<div className="footer-image">
  <img src={FooterImage} alt="Footer" className="full-width-image" />
</div>

    </>
  );
}

export default Sing;